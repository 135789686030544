import React, { useCallback, useEffect, useMemo, useState } from 'react';
import StyledComponent from 'styled-components';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import Paper from '@material-ui/core/Paper';

import FormControl from '@material-ui/core/FormControl';

import Grid from '@material-ui/core/Grid';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { AppText } from '../../constant';
import { ApiManager }  from '../../managers';

const StyledReport = StyledComponent.div`
min-height: 80%;
overflow-y: scroll;
padding: 15px 15px;

.friends-content
{
  text-align: left;
}
.friends-title
{
  color: rgb(35, 83, 115);
}
.preview-title
{
  color: rgb(35, 83, 115);
}
.create-form
{
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-control
{
  width: 100%

  @media(min-width: 600px)
  {
    width: 55%;
  }
}
.print-btn {
  margin-top: 100px;
}
.select-label
{
  width: 100%

  @media(min-width: 600px)
  {
    width: 55%;
  }
}
.selector
{
  width: 100%

  @media(min-width: 600px)
  {
    width: 55%;
  }
}
.main-btn-section
{
  align-self: flex-end;
}
.bottom-section
{
  flex-direction: row;
}
.info-message
{
  white-space:pre-wrap;
}`;


function Reports({ showAlert }) {
  const [isLoading, setIsLoading] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  const [events, setEvents] = useState([]);
  const [report, setReport] = useState();

  const loadData = useCallback(async(eventToUse = undefined) => {
    try {
			console.log('Reports.loadData()');
      setIsLoading(true);
			const response = await ApiManager.SendRequest({
				route: 'classy',
				action: 'report',
        params: {
          event: eventToUse,
        }
			});

			if(response.error !== null) {
				showAlert(true, 'Un-oh', response.error, 'danger');
				return;
			}
      console.log(response);

      setEvents(response.events);
      setReport(response.results);
		}
		catch(err) {
			showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		} finally {
      setIsLoading(false);
    }
  }, []);

  const orderReport = useCallback(async() => {
    try {
      setIsLoading(true);
			const response = await ApiManager.SendRequest({
				route: 'classy',
				action: 'order-report',
        params: {
          event: selectedEvent._id,
        }
			});


      // Get content type from response headers
      const contentType = response.headers['content-type'];
      const fileBlob = new Blob([response.data], { type: contentType });

      console.log(response.headers);

      if (contentType === 'application/pdf') {
        window.open(URL.createObjectURL(fileBlob));
      } else if (contentType === 'application/zip') {
        // Download ZIP file
        const link = document.createElement('a');
        link.href = URL.createObjectURL(fileBlob);
        link.download = 'reports.zip';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error('Unexpected file type:', contentType);
      }
		}
		catch(err) {
      console.error(err);
			showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		} finally {
      setIsLoading(false);
    }
  }, [selectedEvent]); 

  const renderOptions = () => {
    try {
      const items = events.map( (event, i) => (
        <MenuItem key={`event-${i}`} value={event._id.toString()}>{event.name}</MenuItem>
      ));
      return items;
    } catch(err) {
      console.error(err);
      return <></>;
    }
  }

  const reportFields = useMemo(() => report ? Object.keys(report) : [], [report]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Paper>
      <StyledReport>
        <DialogTitle disableTypography>
          <Typography
            variant="h3"
            classes={{h3: 'friends-title'}}
            display='block'
            align={'center'}
          >{AppText.reports.title}</Typography>
          {isLoading ? <CircularProgress /> : null}
        </DialogTitle>
        <DialogContent /*dividers*/ className='friends-content'>
          <Grid container direction="row">
            <Grid item xs={6}>
              {selectedEvent && !isLoading && reportFields.map((reportKey) => (
                <Grid key={reportKey} container direction="row" style={{marginTop: '25px', marginBottom: '25px'}}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      classes={{h5: 'preview-title'}}
                      display='block'
                      align={'left'}
                    >{report[reportKey].description}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      classes={{body2: 'info-message'}}
                      align={'left'}
                    >{report[reportKey].value}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={6}>
              <FormControl className={'select-control'}>
                <InputLabel className={'select-label'} id="job-select-label">Event</InputLabel>
                <Select
                  labelId="job-select-label"
                  id="job-select"
                  value={selectedEvent ? selectedEvent._id.toString() : ''}
                  onChange={(e) =>
                  {
                    try {
                      let theEvent = null;
                      for(let i = 0; i < events.length; i++)
                      {
                        if(events[i]._id.toString() === e.target.value)
                        {
                          theEvent = events[i];
                          break;
                        }
                      }

                      if(!selectedEvent || theEvent._id !== selectedEvent._id) {
                        setSelectedEvent(theEvent);
                        loadData(theEvent);
                      }
                    } catch(err) {
                      console.error(err);
                    }
                  }}
                >
                  {renderOptions()}
                </Select>

                {isLoading || !selectedEvent ? null : <Button className={'print-btn'} onClick={orderReport} variant="outlined">Print Incentive Items</Button>}
              </FormControl>
            </Grid>
           </Grid>
        </DialogContent>
      </StyledReport>
    </Paper>
  );
}

export default Reports;