import ApiRequest    from '../../api/request.js';
import FormLoader    from '../Form/FormLoader';
import {schemaFieldToFormInput} from '../Form/utility';
import Table    from '../Table/Table';
import React 		 from 'react';
import StyledComponent from 'styled-components';

import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const showEventSelector = (model) => ['classymember', 'order'].includes(model);


const Container = StyledComponent.div`
.select-control
{
  width: 100%

  @media(min-width: 600px)
  {
    width: 55%;
  }
}
.select-label
{
  width: 100%

  @media(min-width: 600px)
  {
    width: 55%;
  }
}
.selector
{
  width: 100%

  @media(min-width: 600px)
  {
    width: 55%;
  }
}`;

/**
  State:
    @param 	{bool} 		a		Definition

  Props:
    @param  {String}  model   Name of model we are in context of
*/
export default class DataManager extends React.Component
{
	_isMounted = false;
  _refreshData = false;
	_table = null;

	// MARK: - Constructor
	constructor(props)
	{
		console.log('\tDataManager()');
		super(props);
		this.state =
		{
			isLoading: false,
			tableData: null,

      modelDoc: null,

			updateFormInputs: [],
      createFormInputs: [],

			events: [],
			selectedEvent: null,
		}

		this._table = React.createRef();
	}

	componentDidMount()
	{
		console.log('\tDataManager.componentDidMount()');

    if(!this._isMounted)
    {
	     this._isMounted = true;
	     this.loadData();
    }
	}

  /**
  	Load model
	*/
	loadData = async (eventParam) =>
	{
		console.log('\tDataManager.loadData()');

		const params =
    {
      model: this.props.model,
      params:
      {

      }
    };

    if(this.props.model === 'configuration')
    {
      params.params.display = true;
    }

		const requiresEvent = showEventSelector(this.props.model);
		if(requiresEvent) {
			if(!this.state.selectedEvent && !eventParam) {
				this.getEvents();
				return;
			} else if(eventParam) {
				this.setState({ selectedEvent: eventParam });
				params.params.event = eventParam._id;
			}
		}

		this.setState({ isLoading: true });
    console.log(this.props.model);

		console.log(params);
		//console.log(params);
		try
		{
			// Get data
      // TODO: Setup to use ApiManager instead
			var response = await ApiRequest.sendRequest("post", params, "data/query", this.props.cookies.get('token'));
			if(response.data.error !== null)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
        return;
			}

      //console.log('DataManager.loadData response:\n' + JSON.stringify(response.data, null, 2));

      var updateFormInputs = [];
      var createFormInputs = [];
      var formInput = null;
      for(var i = 0; i < response.data.model.schemaFields.length; i++)
      {
        formInput = await schemaFieldToFormInput(response.data.model.schemaFields[i], this.props.cookies.get('token'));
        if(formInput !== null)
        {
          if(response.data.model.schemaFields[i].managedUpdateForm)
          {
						// TODO: Added this to fix a bug that came up last minute
						// may not be the best fix
						const updateInput = {...formInput};
						updateInput.managedUpdateForm = true;
            updateFormInputs.push(updateInput);
          }
          createFormInputs.push(formInput);
        }
      }

			this.setState({
				isLoading: false,
				tableData: response.data.results,
        modelDoc: response.data.model,
				updateFormInputs: [...updateFormInputs],
        createFormInputs: [...createFormInputs],
			});
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}

	getEvents = async () => {
		console.log('\tDataManager.getEvents()');
		this.setState({ isLoading: true });

		//console.log(params);
		try
		{
			// Get data
			const response = await ApiRequest.sendRequest("post", {
				model: 'event',
				params: {

				},
			}, "data/query", this.props.cookies.get('token'));

			if(response.data.error !== null)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
				return;
			}

			this.setState({
				isLoading: false,
				events: response.data.results,
			});
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}

	tableDidStartLoading = (action) =>
  {
		switch(action)
		{
			case 'update':
				break;
			case 'delete':
				break;
			default: break;
		}
    this.setState({ isLoading: true });
  }

	/**
			@param 	{JSON}		action 		action.type = 'delete, update' and action.data = newly returned data
			@param 	{String}	message 	Message returned from API
			@param 	{String}	error 		Error message or null if not contained

			action:
			{
				type: 'delete',
				data: {record}
			}

	*/
	tableDidFinishLoading = (action, message, error) =>
	{
    console.log(error);
		try
		{
			if(error === null || error === "" || error === undefined)
			{
				let modifiedData = this._table.current.handleAction(action, this.state.tableData);
				this.setState({ isLoading: false, tableData: modifiedData });
				this.props.showAlert(true, 'Success', message, 'success');
			}
			// Failed
			else
			{
				console.log(error);
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', error, 'danger');
			}
		}
		catch(err)
		{
			console.log(err + "\nStack:\n" + err.stack);
		}
	}


	// MARK: - Render
  componentDidUpdate()
  {
    if(this._refreshData)
    {
      this._refreshData = false;
      this.loadData();
    }
  }

  shouldComponentUpdate(nextProps, nextState)
  {
    // Signal in componentdidUpdate that we need to do more work
    if(this.props.model !== nextProps.model)
    {
      this._refreshData = true;

      // Clear table selection since data will be altered
      if(this.table && this.table.current)
      {
        this._table.current.clearAll();
      }
    }

    return (this.props.model !== nextProps.model ||
              this.props.siteManager !== nextProps.siteManager ||
                this.state.isLoading !== nextState.isLoading ||
                  this.state.updateFormInputs !== nextState.updateFormInputs ||
                    this.state.createFormInputs !== nextState.createFormInputs);
  }
	
	onChange = (e) => {
		const selectedEvent = this.state.events.find(({ _id }) => _id === e.target.value);
		if(selectedEvent) {
			this.loadData(selectedEvent);
		}
	};

	render()
	{
		console.log('\tDataManager.render()');
		console.log(this.state.selectedEvent ? this.state.selectedEvent.name : '');

		const requiresEvent = showEventSelector(this.props.model);

  	return (
		<Container>
			<FormLoader isLoading={this.state.isLoading}/>

			{requiresEvent ? (
				<Grid item xs={12} style={{marginTop: '25px'}}>
					<FormControl className={'select-control'}>
						<InputLabel id="job-select-label">Event</InputLabel>
						<Select
							labelId="job-select-label"
							id="job-select"
							value={this.state.selectedEvent ? this.state.selectedEvent._id : ''}
							onChange={this.onChange}
						>	{this.state.events.map((ev) => (
							<MenuItem value={ev._id}>{ev.name}</MenuItem>
						))}
						</Select>
					</FormControl>
				</Grid>
			) : null}

      {this.state.modelDoc && (!requiresEvent || (requiresEvent && this.state.selectedEvent)) &&
  			<Table
  				ref={this._table}
  				data={this.state.tableData}
  				headers={this.state.modelDoc.tableProperties.headers}
  				model={this.state.modelDoc.name}

  				selectAllEnabled={true}
  				multiSelectEnabled={true}

  				defaultSort={this.state.modelDoc.tableProperties.defaultSort}
  				sortEnabled={true}

  				tableDidStartLoading={this.tableDidStartLoading}
  				tableDidFinishLoading={(action, message, error) => this.tableDidFinishLoading(action, message, error)}

  				title={this.state.modelDoc.name + ' records'}

  				isDeleteAvailable={true}

  				isUpdateAvailable={true}
  				updateFormInputs={this.state.updateFormInputs}

  				isCreateAvailable={true}
  				createFormInputs={this.state.createFormInputs}

          isCsvAvailable={true}

  				cookies={this.props.cookies}
  				siteManager={this.props.siteManager}
  			/>}
		</Container>
		);
	}
}
